import React from 'react'
import PropTypes from 'prop-types'

const EcoClear = ({ color, width, ...otherProps }) => {
	return (
		<svg
			fill={color}
			width={width}
			aria-hidden="true"
			focusable="false"
			data-prefix="fas"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 293.52 53.33"
			aria-label="Eco Clear Logo"
			{...otherProps}
		>
			<g xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2">
				<g id="or.....ICE_CREAM_:D" data-name="or.....ICE CREAM? :D">
					<path
						className="cls-1"
						d="M60.88,22.63a9.63,9.63,0,0,0-9.72,9.13H70c-.2-5.23-3-9.13-9.07-9.13m.86,23c3.71,0,6.29-1.85,8.61-4.7l4.5,3.44c-3.31,4.3-7.94,6.42-13.9,6.42C51,50.82,44.8,43.67,44.8,34.34S51.69,17.87,61,17.87c11,0,15.28,8.47,15.28,16.54v2.12H51.16c0,5.49,5.09,9.13,10.58,9.13"
					/>
					<path
						className="cls-1"
						d="M96.75,23.49c-6.49,0-9.8,5.23-9.8,11.12s4,10.65,10.13,10.65a9.3,9.3,0,0,0,7.61-3.51L108.92,46c-3.11,3.44-7.34,4.83-11.91,4.83-9.73,0-16.41-6.62-16.41-16.35S87.22,17.93,97,17.93a16.05,16.05,0,0,1,12,5l-4.5,4.1a9.85,9.85,0,0,0-7.8-3.51"
					/>
					<path
						className="cls-1"
						d="M127.84,45.26c6.36,0,10.33-4.56,10.33-10.92s-4-10.92-10.33-10.92S117.52,28,117.52,34.34s4,10.92,10.32,10.92m0-27.39a16.48,16.48,0,1,1-16.67,16.47,16.48,16.48,0,0,1,16.67-16.47"
					/>
					<path
						className="cls-1"
						d="M166.62,23.49c-6.49,0-9.79,5.23-9.79,11.12s4,10.65,10.12,10.65a9.28,9.28,0,0,0,7.61-3.51L178.8,46c-3.12,3.44-7.35,4.83-11.92,4.83-9.72,0-16.41-6.62-16.41-16.34s6.62-16.55,16.41-16.55a16.07,16.07,0,0,1,12.05,5l-4.5,4.1a9.89,9.89,0,0,0-7.81-3.51"
					/>
					<rect className="cls-1" x="183.03" width="5.96" height="50.03" />
					<path
						className="cls-1"
						d="M211.35,22.63a9.64,9.64,0,0,0-9.73,9.13h18.79c-.2-5.23-3-9.13-9.06-9.13m.86,23c3.7,0,6.28-1.85,8.6-4.7l4.5,3.44c-3.31,4.3-7.94,6.42-13.9,6.42-9.92,0-16.14-7.15-16.14-16.48s6.88-16.47,16.21-16.47c11.05,0,15.28,8.47,15.28,16.54v2.12H201.62c0,5.49,5.09,9.13,10.59,9.13"
					/>
					<path
						className="cls-1"
						d="M237,41c0,3.64,2.58,5.1,6.75,5.1,6.61,0,8.93-4.9,8.93-9.14v-2h-3.18c-5.62,0-12.5,1-12.5,6m8.14-23.09c9.19,0,13.1,5,13.1,10.45v16a50.46,50.46,0,0,0,.26,5.69h-5.29c-.13-1.59-.2-3.18-.2-4.77h-.13c-2.65,4-6.22,5.56-11,5.56-5.89,0-10.85-3.24-10.85-9.4,0-8.07,7.74-10.85,17.27-10.85h4.37V29.25c0-3.24-2.45-6.62-7.48-6.62-4.56,0-6.75,1.92-8.93,3.57l-3.57-3.7a18.48,18.48,0,0,1,12.44-4.63"
					/>
					<path
						className="cls-1"
						d="M271.56,18.66v4.83h.13a10.6,10.6,0,0,1,9.39-5.62,8.34,8.34,0,0,1,2.65.39l-.26,6.42a11.38,11.38,0,0,0-2.91-.46c-5.83,0-9,3.17-9,10.06V50h-6V18.66Z"
					/>
					<path
						className="cls-1"
						d="M25.62,53.33H10.12a1.86,1.86,0,1,1,0-3.72h15.5a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M18.58,47.19H4.13a1.86,1.86,0,0,1,0-3.72H18.58a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M30.84,28.79H26.91a1.86,1.86,0,0,1,0-3.72h3.93a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M27,22.66H8.37a1.86,1.86,0,0,1,0-3.72H27a1.86,1.86,0,0,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M23.24,16.52H12.37a1.86,1.86,0,0,1,0-3.72H23.24a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M19.07,10.39H16.7a1.86,1.86,0,1,1,0-3.72h2.37a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M33.22,34.93H29.3a1.86,1.86,0,1,1,0-3.72h3.92a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M17.81,28.79H5.27a1.86,1.86,0,0,1,0-3.72H17.81a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M18.88,34.93H2.27a1.86,1.86,0,1,1,0-3.72H18.88a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M18.47,41.06H1.86a1.86,1.86,0,0,1,0-3.72H18.47a1.86,1.86,0,0,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M33.88,41.06H29.81a1.86,1.86,0,0,1,0-3.72h4.07a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M31.66,47.19H27.59a1.86,1.86,0,0,1,0-3.72h4.07a1.86,1.86,0,1,1,0,3.72"
					/>
					<path
						className="cls-1"
						d="M289.58,6.42a3.94,3.94,0,1,1-3.94,3.93A3.91,3.91,0,0,1,289.58,6.42Zm0,7.3a3.27,3.27,0,0,0,3.24-3.37,3.24,3.24,0,1,0-6.48,0A3.26,3.26,0,0,0,289.58,13.72Zm-1.51-5.67h1.74c1.09,0,1.61.43,1.61,1.31a1.19,1.19,0,0,1-1.22,1.26l1.33,2h-.76l-1.24-2h-.76v2h-.7Zm.7,2h.72c.63,0,1.23,0,1.23-.77s-.55-.71-1-.71h-.92Z"
					/>
				</g>
			</g>
		</svg>
	)
}

EcoClear.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
EcoClear.defaultProps = {
	color: 'currentColor',
	width: '24',
}
export default EcoClear
