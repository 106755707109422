import React, { useState } from 'react'
import {
	VideoContainer,
	Novideo,
	ThumbnailsContainer,
	ThumbContainer,
	Thumbs,
	SvgContainer,
	StyledSvg,
	ThumbsTitle,
} from './styles'

const FeaturedVideos = ({ vid }) => {
	const [currentVid, setCurrentVid] = useState(
		vid != null ? vid.items[0]?.youtubeVideos[0] : null
	)
	const [...filteredThumbs] = vid != null ? vid.items.map(x => x) : []
	return (
		<VideoContainer>
			{filteredThumbs.length > 0 ? (
				<ThumbnailsContainer>
					{filteredThumbs.map((thumb, i) => {
						return (
							<ThumbContainer key={i}>
								<ThumbsTitle
									onClick={() => {
										setCurrentVid(thumb.youtubeVideos[0])
									}}
								>
									{thumb.name}
								</ThumbsTitle>
								<div css={{ position: 'relative' }}>
									<Thumbs
										src={`https://img.youtube.com/vi/${thumb.youtubeVideos[0]}/mqdefault.jpg`}
										loading="lazy"
									></Thumbs>
									<SvgContainer
										onClick={() => {
											setCurrentVid(thumb.youtubeVideos[0])
										}}
									>
										<StyledSvg width="60px" height="60px" color="#fff" />
									</SvgContainer>
								</div>
							</ThumbContainer>
						)
					})}
				</ThumbnailsContainer>
			) : (
				''
			)}
			{currentVid != null ? (
				<iframe
					width="100%"
					height="100%"
					src={`https://www.youtube.com/embed/${currentVid}`}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					loading="lazy"
				></iframe>
			) : (
				<Novideo>No video at this time</Novideo>
			)}
		</VideoContainer>
	)
}

export default FeaturedVideos
