import React from 'react'
import PropTypes from 'prop-types'
const MineralSwimLogo = ({ size, primary, secondary, ...otherProps }) => (
	<svg
		id="mineral-swim-logo"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		aria-label="Mineral Swim Logo"
		width={size ? size : ''}
		viewBox="0 0 280 45"
		{...otherProps}
	>
		<g id="mineral">
			<path
				id="m"
				fill={primary}
				d="M0,21.44a5.52,5.52,0,0,1,.77-3.12,3.18,3.18,0,0,1,2.91-1.19H28.78q4.24,0,6,2.18a9.46,9.46,0,0,1,1.78,6.15V44.23H33.91V25.51a11.7,11.7,0,0,0-.23-2.29,5.06,5.06,0,0,0-.82-1.89,3.91,3.91,0,0,0-1.55-1.27,6.11,6.11,0,0,0-2.53-.46H19.64V44.23H17V19.6H4.31a2.11,2.11,0,0,0-1.2.3c-.29.21-.43.75-.43,1.63v22.7H0Z"
			/>
			<path
				id="i"
				fill={primary}
				d="M42.12,12.44v-4H45v4Zm.11,4.7h2.65V44.23H42.23Z"
			/>
			<path
				id="n"
				fill={primary}
				d="M50.5,21.44a5.52,5.52,0,0,1,.77-3.12,3.18,3.18,0,0,1,2.91-1.19h10q4.25,0,6,2.18A9.46,9.46,0,0,1,72,25.46V44.23H69.31V25.51a11,11,0,0,0-.23-2.29,4.82,4.82,0,0,0-.77-1.89,4,4,0,0,0-1.58-1.27,6.11,6.11,0,0,0-2.53-.46H54.85a2.11,2.11,0,0,0-1.2.3c-.29.21-.44.75-.44,1.63v22.7H50.55Z"
			/>
			<path
				id="e"
				fill={primary}
				d="M85.92,44.23a9.48,9.48,0,0,1-4.62-1,7.31,7.31,0,0,1-2.87-2.78A12.24,12.24,0,0,1,77,36.23a31.86,31.86,0,0,1-.41-5.34,29.39,29.39,0,0,1,.54-6.06A10.86,10.86,0,0,1,79,20.44a7.94,7.94,0,0,1,3.55-2.68,15,15,0,0,1,5.67-.92,19.25,19.25,0,0,1,4.65.54,9.75,9.75,0,0,1,3.71,1.75A8.19,8.19,0,0,1,99,22.34a12.36,12.36,0,0,1,.86,4.85A5.75,5.75,0,0,1,99,30.6q-.81,1-3.09,1H79.31a28.4,28.4,0,0,0,.23,3.57,10.58,10.58,0,0,0,.92,3.26,5.49,5.49,0,0,0,5.44,3.29H99.21v2.46Zm10-15a.93.93,0,0,0,.89-.4A1.59,1.59,0,0,0,97,28v-.38a12.31,12.31,0,0,0-.58-4.09A5.92,5.92,0,0,0,94.72,21a6.79,6.79,0,0,0-2.79-1.32,16.75,16.75,0,0,0-3.73-.38,14.83,14.83,0,0,0-3.93.46,5.86,5.86,0,0,0-2.79,1.6,7,7,0,0,0-1.63,3,17.65,17.65,0,0,0-.54,4.8Z"
			/>
			<path
				id="r"
				fill={primary}
				d="M104.42,26.44c0-3.1.74-5.42,2.2-7s3.75-2.31,6.85-2.31h1.84v2.46h-1.84c-2.32,0-4,.61-4.93,1.83s-1.46,3.11-1.46,5.66V44.23h-2.66Z"
			/>
			<path
				id="a"
				fill={primary}
				d="M131.31,17.13q3.38,0,4.93,1.79a7.4,7.4,0,0,1,1.56,5V40.65a4.22,4.22,0,0,1-.74,2.69c-.5.59-1.56.89-3.2.89H123.44c-2.69,0-4.59-.65-5.68-2s-1.63-3.38-1.63-6.21.55-4.87,1.66-6.13,3-1.89,5.65-1.89h11.7V23.51a4.65,4.65,0,0,0-.84-2.72,3.41,3.41,0,0,0-3-1.19H118.17V17.13Zm-8.59,13.34a4.18,4.18,0,0,0-2,.41A3,3,0,0,0,119.55,32a4.47,4.47,0,0,0-.56,1.78,15,15,0,0,0-.13,2.22A15.49,15.49,0,0,0,119,38.3a4.65,4.65,0,0,0,.56,1.82,3.05,3.05,0,0,0,1.17,1.22,4,4,0,0,0,2,.43h11.14a1.19,1.19,0,0,0,1-.38,1.41,1.41,0,0,0,.28-.84V30.48Z"
			/>
			<path id="l" fill={primary} d="M143.31,8.45H146V44.23h-2.66Z" />
		</g>
		<g id="swim">
			<g id="brand">
				<path
					id="s-right"
					fill={secondary}
					d="M170.13,0a11.93,11.93,0,0,0-.91,1A11,11,0,0,0,167,9a13.09,13.09,0,0,0,1.63,5.12,46.13,46.13,0,0,0,3.21,5,57.33,57.33,0,0,1,4.37,7,11.25,11.25,0,0,1,1.32,5.8A10,10,0,0,1,176,36.65a10.49,10.49,0,0,1-1.57,2,.2.2,0,0,1,.07-.26,9.4,9.4,0,0,0,1.55-5.28,9.68,9.68,0,0,0-.84-3.63,20.87,20.87,0,0,0-2.9-4.73c-1.54-2-3.2-3.75-4.84-5.59a43,43,0,0,1-3.8-4.73,14.22,14.22,0,0,1-1.88-3.68A7.84,7.84,0,0,1,162,5.17,7.83,7.83,0,0,1,166.61.53a9.2,9.2,0,0,1,3.33-.5Z"
				/>
				<path
					id="s-left"
					fill={primary}
					d="M162.5,45.24a8.78,8.78,0,0,0,2-2.6,11.64,11.64,0,0,0,.55-9.09,18.79,18.79,0,0,0-2.2-4.36c-1-1.56-2.08-3-3.11-4.57a45.75,45.75,0,0,1-3.36-5.57,10.38,10.38,0,0,1-.4-9.14,11.25,11.25,0,0,1,2.19-3.23.13.13,0,0,1,.14,0c-.29.5-.58,1-.83,1.51a8.74,8.74,0,0,0-.49,6.45,14.4,14.4,0,0,0,1.73,3.68,27.15,27.15,0,0,0,2,2.78c1.41,1.76,2.92,3.38,4.4,5a49.13,49.13,0,0,1,3.34,4,18.13,18.13,0,0,1,2.22,4.08,8,8,0,0,1,.08,5.81,7.86,7.86,0,0,1-4.55,4.8,8.81,8.81,0,0,1-3.52.58Z"
				/>
			</g>
			<path
				id="w"
				fill={secondary}
				d="M190.19,40.86a4.16,4.16,0,0,0,.23.54c.08.18.19.28.33.28s.29-.11.36-.34a4.29,4.29,0,0,1,.15-.43L196.94,19a2.72,2.72,0,0,1,1.17-1.81,2.68,2.68,0,0,1,2.76,0A2.77,2.77,0,0,1,202.05,19l5.68,21.89a3.84,3.84,0,0,0,.15.43c.07.22.19.34.36.34s.24-.1.33-.28l.23-.54,6.08-23.73h2.66L210.9,42.29a4.91,4.91,0,0,1-.8,1.56,2.17,2.17,0,0,1-1.81.69,2.72,2.72,0,0,1-1.23-.26,2.59,2.59,0,0,1-.82-.61,2.77,2.77,0,0,1-.48-.74,2.44,2.44,0,0,1-.23-.69L200,21.07a4.67,4.67,0,0,0-.23-.66c-.08-.21-.18-.31-.28-.31s-.19.1-.28.31a5.66,5.66,0,0,0-.23.66l-5.52,21.17c-.07.2-.15.43-.26.69a2.7,2.7,0,0,1-.46.74,2.75,2.75,0,0,1-.79.61,2.58,2.58,0,0,1-1.25.26,2.17,2.17,0,0,1-1.82-.69,4.51,4.51,0,0,1-.79-1.56l-6.65-25.16h2.66Z"
			/>
			<path
				id="i"
				fill={secondary}
				d="M220.31,8.83h2.87v4h-2.87Zm.11,8.3h2.65v27.1h-2.66Z"
			/>
			<path
				id="m"
				fill={secondary}
				d="M228.69,21.43a5.52,5.52,0,0,1,.77-3.12,3.22,3.22,0,0,1,2.91-1.18h25.11c2.82,0,4.84.73,6,2.18a9.52,9.52,0,0,1,1.83,6.16V44.23h-2.66V25.51a11.88,11.88,0,0,0-.23-2.3,5.06,5.06,0,0,0-.82-1.89A4,4,0,0,0,260,20.05a6.11,6.11,0,0,0-2.53-.46h-9.18V44.23h-2.66V19.59H233a2.11,2.11,0,0,0-1.2.3c-.29.21-.43.75-.43,1.64v22.7h-2.66Z"
			/>
		</g>
		<g id="TM">
			<polygon
				id="T"
				fill={secondary}
				points="270.38 23.48 270.38 17.84 268.27 17.84 268.27 17.08 273.34 17.08 273.34 17.84 271.22 17.84 271.22 23.48 270.38 23.48"
			/>
			<path
				id="M"
				fill={secondary}
				d="M274.18,23.48V17.13h1.27L277,21.65c.13.43.24.74.3,1,.07-.23.19-.57.34-1l1.53-4.47h1.17v6.39h-.82V18.13l-1.85,5.35h-.77L275,18v5.44Z"
			/>
		</g>
	</svg>
)
MineralSwimLogo.propTypes = {
	size: PropTypes.string,
	primary: PropTypes.string,
	secondary: PropTypes.string,
}
MineralSwimLogo.defaultProps = {
	size: '',
	primary: '#57c8de',
	secondary: '#598aa9',
}
export default MineralSwimLogo
